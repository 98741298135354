import React, { useEffect, useState } from "react";
import Terminal from "terminal-in-react";
import "./App.css";
import { theme } from "./theme";

function App() {
  const [currentStep, setCurrentStep] = useState(0);
  const [name, setName] = useState("");

  function blank() {
    return "";
  }

  function unknownArgument() {
    return "I'm sorry, I did not understand your command";
  }

  function greet(input: string[]) {
    setCurrentStep(1);
    const name = input.join(" ");
    setName(name);
    return `Hello ${name}, care for some brain work (Y/N)?`;
  }

  function goodbye() {
    return (
      <div>
        <div>Too bad</div>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dQw4w9WgXcQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  }

  function askRiddle() {
    setCurrentStep(2);
    return (
      <div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: theme.typography.heading,
            width: "100%",
            textAlign: "center",
          }}
        >
          Einstein’s (new) Riddle
        </div>
        <div style={{ height: theme.spacing }} />
        <div>
          Legend has it that at the age of 8, Albert Einstein came up with this
          riddle. He estimated only 2% of the world’s population is smart enough
          to solve it.
        </div>
        <div style={{ height: theme.spacing }} />
        <div>
          Upon arriving at the House of Legends, one cheeky Einstein (#T134 to
          be exact), thought the riddle could use an update. Are you in the 2%?
        </div>
        <div style={{ height: theme.spacing }} />
        <div style={{ fontWeight: "bold" }}>So here’s the sitch:</div>
        <div style={{ height: theme.spacing }} />
        <div>
          5 Legends live in five houses in a row: Freud, Oprah, Dali, Marie
          Curie, and Tesla.
        </div>
        <div style={{ height: theme.spacing }} />

        <div>
          Their favorite drinks are: Mountain Dew, Bubble Tea, Kombucha, Coke
          and Powerade.
        </div>
        <div style={{ height: theme.spacing }} />

        <div>
          Everyone smokes except for one: One smokes a pipe, one hits a bong,
          one vapes, and one likes cigars.
        </div>
        <div style={{ height: theme.spacing }} />

        <div>
          Music comes out of each house: The Weeknd, Imagine Dragon, The Pixies,
          Daft Punk, and Whitney Houston.{" "}
        </div>
        <div style={{ height: theme.spacing }} />

        <div>
          They all admire Superheros: Storm, The Incredible Hulk, Watchmen’s
          Rorschach, Wonder Woman, and The Riddler.{" "}
        </div>
        <div style={{ height: theme.spacing }} />
        <div style={{ fontWeight: "bold" }}>
          Alright, now this is what we know:
        </div>
        <ol>
          <li>Marie Curie enjoys a particular song by Imagine Dragons.</li>
          <li>Oprah’s favorite superhero is, of course, Wonder Woman.</li>
          <li>Tesla drinks powerade (for energy).</li>
          <li>
            The exquisite voice of Whitney Houston flows out of one house. The
            Legend in the house to the left of it listens to The Weeknd.
          </li>
          <li>
            The legend who likes The Weeknd also thinks there’s no better drink
            than a cold, fresh Coke.
          </li>
          <li>
            The Legend who doesn’t smoke (because it’s bad for you) admires the
            Incredible Hulk.
          </li>
          <li>
            The Legend in the center house drinks a cool green Mountain Dew.
          </li>
          <li>
            The Legend who listens to The Pixies smokes a bong (but be quiet
            about it).
          </li>
          <li>
            The plaque on the first house reads: “Residence of the great
            Salvador Dali”.
          </li>
          <li>
            One legend’s favorite superhero is actually a villain - The Riddler.
            Next to him lives a Legend who prefers vaping to smoking.
          </li>
          <li>
            The Legend who likes X-Men’s Storm lives next to the Legend who hits
            the bong occasionally.
          </li>
          <li>
            The Legend who smokes a pipe also drinks Kombucha (for probiotics).
          </li>
          <li>Freud smokes a cigar, because Freud.</li>
          <li>
            Salvador Dali lives next to a house where the music of Daft Punk
            flows from open windows- and quite likes it.
          </li>
          <li>
            The Legend who vapes has a neighbor who drinks a strange, colorful
            Bubble Tea.
          </li>
        </ol>
        <div>The question is: What is Einstein’s favorite car?</div>
        <div style={{ height: theme.spacing }} />
        <div>
          P.S. Don’t be shy to ask for a hint, you won’t make it without.
        </div>
      </div>
    );
  }

  function hint() {
    return (
      <table className="tg">
        <thead>
          <tr>
            <th className="tg-0pky">
              <span>LEGEND</span>
            </th>
            <th className="tg-0pky">
              <span>LEGEND</span>
            </th>
            <th className="tg-0pky">
              <span>LEGEND</span>
            </th>
            <th className="tg-0pky">
              <span>LEGEND</span>
            </th>
            <th className="tg-0pky">
              <span>LEGEND</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="tg-0pky">
              <span>SMOKE</span>
            </td>
            <td className="tg-0pky">
              <span>SMOKE</span>
            </td>
            <td className="tg-0pky">
              <span>SMOKE</span>
            </td>
            <td className="tg-0pky">
              <span>SMOKE</span>
            </td>
            <td className="tg-0pky">
              <span>SMOKE</span>
            </td>
          </tr>
          <tr>
            <td className="tg-0pky">
              <span>MUSIC</span>
            </td>
            <td className="tg-0pky">
              <span>MUSIC</span>
            </td>
            <td className="tg-0pky">
              <span>MUSIC</span>
            </td>
            <td className="tg-0pky">
              <span>MUSIC</span>
            </td>
            <td className="tg-0pky" style={{ textAlign: "right" }}>
              <span>MUSIC</span>
            </td>
          </tr>
          <tr>
            <td className="tg-0pky" style={{ textAlign: "right" }}>
              <span>DRINK</span>
            </td>
            <td className="tg-0pky">
              <span>DRINK</span>
            </td>
            <td className="tg-0pky" style={{ textAlign: "right" }}>
              <span>DRINK</span>
            </td>
            <td className="tg-0pky">
              <span>DRINK</span>
            </td>
            <td className="tg-0pky">
              <span>DRINK</span>
            </td>
          </tr>
          <tr>
            <td className="tg-0pky">
              <span>SUPERHERO</span>
            </td>
            <td className="tg-0pky">
              <span>SUPERHERO</span>
            </td>
            <td className="tg-0pky">
              <span>SUPERHERO</span>
            </td>
            <td className="tg-0pky">
              <span>SUPERHERO</span>
            </td>
            <td className="tg-0pky">
              <span>SUPERHERO</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  function wrongAnswer() {
    const responses = [
      "No. Wrong answer",
      "Nah, nice try though.",
      "Nope.",
      "Sorry... But no.",
    ];
    return responses[getRandomInt(responses.length)];
  }

  function acceptRiddleAnswer(input: string[]) {
    switch (input[0]?.toLowerCase()) {
      case "hint":
        return hint();
      case "bmw":
        setCurrentStep(3);
        return `Well done ${name}, that is very, very true. Say hi at phase1@houseoflegends.com`;
      default:
        return wrongAnswer();
    }
  }

  function acceptGreetAnswer(input: string[]) {
    switch (input[0]?.toLowerCase()) {
      case "y":
        return askRiddle();
      case "n":
        return goodbye();
      default:
        return unknownArgument();
    }
  }

  const functions = {
    0: greet,
    1: acceptGreetAnswer,
    2: acceptRiddleAnswer,
    3: blank,
  };

  useEffect(() => {
    // hack to remove scrollbars
    //@ts-ignore
    document.getElementsByClassName(
      "terminal-base"
      //@ts-ignore
    )[0].firstChild.firstChild.style.overflow = "auto";
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        background: "black",
      }}
    >
      <Terminal
        hideTopBar
        allowTabs={false}
        color="green"
        backgroundColor="black"
        barColor="black"
        style={{ fontSize: "1.1rem", position: "absolute", top: 20 }}
        //@ts-ignore
        commandPassThrough={functions[currentStep]}
        commands={{
          clear: () => unknownArgument(),
          help: () => unknownArgument(),
          echo: () => unknownArgument(),
          "edit-line": () => unknownArgument(),
        }}
        description={{
          "open-google": "opens google.com",
          showmsg: "shows a message",
          alert: "alert",
          popup: "alert",
        }}
        msg="Greetings Legend. What do they call you?"
      />
    </div>
  );
}

export default App;
